import React, { useState, useEffect, useContext } from "react";
import adminLayout from "../hoc/adminLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/css/FieldAgentList.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  FaEdit,
  FaEye,
  FaTrashAlt,
  FaSortUp,
  FaSortDown,
} from "react-icons/fa";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

const ScheduleList = () => {
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      fetchEmployeeData(user);
    }
  }, [user]);

  const fetchEmployeeData = (user) => {
    const token = localStorage.getItem("token");
    axios
      .post(
        "https://uat-tracking.rmtec.in/api/scheduledVisitTrackId/getAllScheduledVisitTrackId",
        {
          query: "",
          variables: {
            userId: `${user && user?.fieldAgentId}`,
            // teamId: `${user && user?.privilege.team.teamId}`,
            teamId: ``,

            roleId: `${user && user?.role.roleId}`,
            agentMail: "",
            startDate: "",
            endDate: "",
            search: "",
            page: 0,
            limit: 30,
          },
        },
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )

      .then((response) => {
        console.log(
          "------response-----schedule list------>",
          response.data.data.content
        );
        if (response.data.data.content.length > 0) {
          setEmployees(response?.data?.data?.content);
        } else {
          console.error("Expected an array but received:", response.data);
          setEmployees([]);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (field) => {
    const newSortOrder =
      sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  // Filter employees based on search term
  const filteredEmployees = employees.filter((employee) => {
    return (
      employee.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.fieldAgentEmail
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      employee.mobile?.toString().includes(searchTerm) ||
      employee.status?.toString().includes(searchTerm) ||
      (employee.isActive ? "Yes" : "No")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  });

  // Sort employees based on selected field and order
  const sortedEmployees = [...filteredEmployees].sort((a, b) => {
    const aValue =
      a[sortField] != null
        ? typeof a[sortField] === "string"
          ? a[sortField].toLowerCase()
          : a[sortField]
        : "";
    const bValue =
      b[sortField] != null
        ? typeof b[sortField] === "string"
          ? b[sortField].toLowerCase()
          : b[sortField]
        : "";
    return sortOrder === "asc"
      ? aValue > bValue
        ? 1
        : -1
      : aValue < bValue
      ? 1
      : -1;
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEmployees = sortedEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(sortedEmployees.length / itemsPerPage);

  console.log("----------schedule---list---employees----->", employees);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Schedule List - RealTimeTracking</title>
          <meta
            name="description"
            content="Overview of field agent performance and metrics."
          />
          <meta name="keywords" content="dashboard, field agent, management" />
        </Helmet>
      </HelmetProvider>

      <h3 className="title">Schedule List</h3>

      <div className="mt-5 fieldagentlist">
        <div
          className="row mb-3"
          style={{
            backgroundColor: "#0d6efd",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div className="col-md-2">
            <select className="form-control" required>
              <option value="">Select Name</option>
              <option value="role1">Name 1</option>
              <option value="role2">Name 2</option>
              <option value="role3">Name 3</option>
            </select>
          </div>
          <div className="col-md-3">
            <input type="date" className="form-control" required />
          </div>
          <div className="col-md-3">
            <input type="date" className="form-control" required />
          </div>
          <div className="col-md-2">
            <select className="form-control" required>
              <option value="">Select Team</option>
              <option value="team1">Team 1</option>
              <option value="team2">Team 2</option>
              <option value="team3">Team 3</option>
            </select>
          </div>
          <div className="col-md-2">
            <select className="form-control" required>
              <option value="">Select Manager</option>
              <option value="manager1">Manager 1</option>
              <option value="manager2">Manager 2</option>
              <option value="manager3">Manager 3</option>
            </select>
          </div>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th onClick={() => handleSort("name")}>
                Agent Name{" "}
                {sortField === "name" &&
                  (sortOrder === "asc" ? (
                    <FaSortUp style={{ color: "white" }} />
                  ) : (
                    <FaSortDown style={{ color: "white" }} />
                  ))}
              </th>
              <th onClick={() => handleSort("fieldAgentEmail")}>
                Schedule date{" "}
                {sortField === "fieldAgentEmail" &&
                  (sortOrder === "asc" ? (
                    <FaSortUp style={{ color: "white" }} />
                  ) : (
                    <FaSortDown style={{ color: "white" }} />
                  ))}
              </th>
              <th onClick={() => handleSort("mobile")}>
                Address{" "}
                {sortField === "mobile" &&
                  (sortOrder === "asc" ? (
                    <FaSortUp style={{ color: "white" }} />
                  ) : (
                    <FaSortDown style={{ color: "white" }} />
                  ))}
              </th>
              <th onClick={() => handleSort("status")}>
                Status{" "}
                {sortField === "status" &&
                  (sortOrder === "asc" ? (
                    <FaSortUp style={{ color: "white" }} />
                  ) : (
                    <FaSortDown style={{ color: "white" }} />
                  ))}
              </th>
              <th onClick={() => handleSort("isActive")}>
                Note{" "}
                {sortField === "isActive" &&
                  (sortOrder === "asc" ? (
                    <FaSortUp style={{ color: "white" }} />
                  ) : (
                    <FaSortDown style={{ color: "white" }} />
                  ))}
              </th>
              <th onClick={() => handleSort("isActive")}>
                Action{" "}
                {sortField === "isActive" &&
                  (sortOrder === "asc" ? (
                    <FaSortUp style={{ color: "white" }} />
                  ) : (
                    <FaSortDown style={{ color: "white" }} />
                  ))}
              </th>
            </tr>
          </thead>
          <tbody>
            {employees.length > 0 ? (
              employees.map((employee) => (
                <tr key={employee.fieldAgentId}>
                  <td>{employee.assignee}</td>
                  <td>{employee.time}</td>
                  <td>{employee.description}</td>
                  <td>{employee.taskStatus}</td>
                  <td>{employee.notes}</td>
                  <td>
                    <FaEdit
                      className="action-icon"
                      title="Edit"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                    />
                    <FaEye
                      className="action-icon"
                      title="View"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                    />
                    <FaTrashAlt
                      className="action-icon"
                      title="Delete"
                      style={{ cursor: "pointer", color: "red" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No employees found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <nav aria-label="Page navigation">
          <ul className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <li
                key={index + 1}
                className={`page-item ${
                  index + 1 === currentPage ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default adminLayout(ScheduleList);
