// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .container {
    padding: 20px;
} */

.row {
    margin: 20px 0;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

h4 {
    text-align: center;
}
.H_copyright{
    display: none !important;
}

.H_logo{
display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/charts.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,wBAAwB;AAC5B;;AAEA;AACA,wBAAwB;AACxB","sourcesContent":["/* .container {\n    padding: 20px;\n} */\n\n.row {\n    margin: 20px 0;\n}\n\nh2 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\nh4 {\n    text-align: center;\n}\n.H_copyright{\n    display: none !important;\n}\n\n.H_logo{\ndisplay: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
