import React, { useEffect, useState, useRef, useContext } from "react";
import { Pie, Bar, Line } from "react-chartjs-2";
import "../assets/css/charts.css";

// import 'https://js.api.here.com/v3/3.1/mapsjs-ui.css'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
} from "chart.js";
import { AuthContext } from "../context/AuthContext";

// Register the necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement
);

const OverviewComponent = () => {
  const [counter, setCounter] = useState(0);
  const [numberValue, setNumberValue] = useState(100); // Initialize with some value
  const [userData, setUserData] = useState(null);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setModalData] = useState(null); // State for modal data
  const { user } = useContext(AuthContext);
  const [overViewCount, setOverViewCount] = useState(null);

  useEffect(() => {
    if (user) {
      setUserData(user);
      fetchTrackingData(user);
      handleOverViewCount();
    }
  }, [user]);

  const handleOverViewCount = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `https://uat-tracking.rmtec.in/api/teams/count/${user.fieldAgentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Authorization header with Bearer token
          },
        }
      );

      const data = await response.json();

      if (data) {
        setOverViewCount(data);
        console.log("Tracking data fetched:", data);
      } else {
        console.error("Error fetching tracking data:", data);
      }
    } catch (error) {
      console.error("Error fetching live tracking data:", error);
    } finally {
      setIsLoading(false); // Set loading to false once data is fetched
    }
  };

  // Fetch live tracking data from the API
  const fetchTrackingData = async (user) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        "https://uat-tracking.rmtec.in/api/liveTrackingId/getAllLiveTrackingIdDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Authorization header with Bearer token
          },

          body: JSON.stringify({
            query: "",
            variables: {
              fieldAgentId: user?.fieldAgentId,
              roleId: user.role.userId,
              agentMail: "",
              startDate: "",
              timeSpentDetail: "",
              endDate: "",
              teamId: "",
              agentMail: "",
              search: "",
              page: 0,
              limit: 20,
            },
          }),
        }
      );

      const data = await response.json();
      if (data.statusCode === 200) {
        setTrackingData(data.data.content);
        console.log("Tracking data fetched:", data.data.content);
      } else {
        console.error("Error fetching tracking data:", data);
      }
    } catch (error) {
      console.error("Error fetching live tracking data:", error);
    } finally {
      setIsLoading(false); // Set loading to false once data is fetched
    }
  };

  // Load HERE Maps scripts dynamically
  const loadHereMapsScripts = () => {
    const script = document.createElement("script");
    script.src = "https://js.api.here.com/v3/3.1/mapsjs.bundle.js"; // HERE Maps script URL
    script.type = "module";
    script.onload = () => setMapsLoaded(true); // Set mapsLoaded to true once the script loads
    script.onerror = () => console.error("Failed to load HERE Maps script");
    document.body.appendChild(script);
  };

  useEffect(() => {
    loadHereMapsScripts(); // Load HERE Maps script on component mount
  }, []); // Empty array ensures script loads only once

  // Initialize HERE Map only once
  useEffect(() => {
    if (mapsLoaded && window.H) {
      try {
        // Initialize the HERE Map with your API key
        const platform = new window.H.service.Platform({
          apikey: "pQRBSzF1TdNTk2W6Z5pCNpDtmGAYH624qC7LU0cf6Xw", // Replace with your actual API key
        });

        const defaultLayers = platform.createDefaultLayers();

        // Check if the map already exists to avoid reinitialization
        const mapContainer = document.getElementById("map");
        if (mapContainer.dataset.initialized) return; // Skip reinitialization

        const map = new window.H.Map(
          mapContainer, // The map container div
          defaultLayers.vector.normal.map,
          {
            center: { lat: 20.5937, lng: 78.9629 }, // Default center coordinates (e.g., India)
            zoom: 4,
          }
        );

        mapContainer.dataset.initialized = true; // Mark the map as initialized

        const mapEvents = new window.H.mapevents.MapEvents(map);
        new window.H.mapevents.Behavior(mapEvents);
        window.H.ui.UI.createDefault(map, defaultLayers);

        // Store the map instance globally or in a ref if needed
        window.mapInstance = map;

        console.log("Map initialized successfully");
      } catch (error) {
        console.error("Error initializing HERE Maps:", error);
      }
    }
  }, [mapsLoaded]);

  // Update Markers when trackingData changes
  useEffect(() => {
    if (window.mapInstance && trackingData.length > 0) {
      try {
        const map = window.mapInstance;

        // Clear previous markers
        map.removeObjects(map.getObjects());

        // Plot all tracking locations on the map
        trackingData.forEach((data) => {
          const { latitude, longitude, date, fieldAgentId, agentMailId } = data;

          // Convert latitude and longitude to numbers
          const lat = parseFloat(latitude);
          const lng = parseFloat(longitude);

          // Check if latitude and longitude are valid numbers
          if (!isNaN(lat) && !isNaN(lng)) {
            // Create a marker for each location
            const marker = new window.H.map.Marker({ lat, lng });
            marker.setData({
              fieldAgentId,
              agentMailId,
              date,
            });

            // Add marker to the map
            map.addObject(marker);

            // Open modal with data when marker is tapped
            marker.addEventListener("tap", function (evt) {
              const data = evt.target.getData();
              setModalData(data); // Set modal data
            });
          } else {
            console.error("Invalid coordinates:", lat, lng);
          }
        });

        console.log("Markers added to the map");
      } catch (error) {
        console.error("Error adding markers to the map:", error);
      }
    }
  }, [trackingData]); // Depend only on trackingData for marker updates

  return (
    <div className="container mt-5">
      {/* <h2>Dashboard</h2> */}

      {/* Cards Section */}
      <div className="row mb-4">
        <div className="col-xl-3 col-sm-6 mb-3">
          <div className="card text-white  o-hidden h-100">
            <div className="card-body">
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                Active field Agents
              </div>
              <div
                className="mr-5"
                style={{
                  fontWeight: "600",
                  fontSize: "30px",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                {overViewCount?.fieldAgentCount}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3">
          <div className="card text-white  o-hidden h-100">
            <div className="card-body">
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                Total Routes Today
              </div>
              <div
                className="mr-5"
                style={{
                  fontWeight: "600",
                  fontSize: "30px",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                {0}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6 mb-3">
          <div className="card text-white  o-hidden h-100">
            <div className="card-body">
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "18px",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                SOS Alerts
              </div>
              <div
                className="mr-5"
                style={{
                  fontWeight: "600",
                  fontSize: "30px",
                  textAlign: "center",
                  color: "#000",
                }}
              >
                {overViewCount?.sosCount}
              </div>
            </div>
          </div>
        </div>
        {user.role.role !== "FieldAgent" && (
          <div className="col-xl-3 col-sm-6 mb-3">
            <div className="card text-white bg-success o-hidden h-100">
              <div className="card-body">
                <div
                  className="mr-5"
                  style={{ fontWeight: "600", fontSize: "20px" }}
                >
                  FieldAgent Schedule
                </div>
              </div>
              <div className="card-footer text-white clearfix small z-1">
                <button
                  type="button"
                  className="btn btn-light float-left mr-2"
                  style={{
                    fontWeight: "600",
                    fontSize: "10px",
                    marginRight: "10px",
                  }}
                  onClick={() => (window.location.href = "/schedule-list")}
                >
                  Schedule List
                </button>
                <button
                  type="button"
                  className="btn btn-light float-left"
                  style={{ fontWeight: "600", fontSize: "10px" }}
                  onClick={() => (window.location.href = "/schedule-add")}
                >
                  Add a New Schedule
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Charts Section */}
      <div className="row">
        <div>
          {/* <h1>Live Tracking Map</h1> */}
          <div
            id="map"
            style={{
              width: "100%",
              height: "500px",
              border: "1px solid #ccc",
              marginTop: "20px",
            }}
          ></div>

          {isLoading && <p>Loading tracking data...</p>}

          {/* Modal for marker details */}
          {modalData && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "8px",
                zIndex: 1000,
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3>Agent Details</h3>
              <p>
                <strong>Agent Email:</strong> {modalData.agentMailId}
              </p>
              <p>
                <strong>Date:</strong> {modalData.date}
              </p>
              <button
                style={{
                  marginTop: "10px",
                  padding: "8px 16px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => setModalData(null)}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>

      <div>
        {/* <h2>Overview Component</h2>
      <div id="map" style={{ width: '100%', height: '500px' }}></div>
      {isLoading ? (
        <p>Loading tracking data...</p>
      ) : (
        <p>Showing {trackingData.length} live locations on the map.</p>
      )}
    </div> */}
      </div>
    </div>
  );
};

export default OverviewComponent;
