// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-top {
    /* position: fixed; */
    position: static !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Nav.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,MAAM;IACN,QAAQ;IACR,OAAO;IACP,aAAa;AACjB","sourcesContent":[".fixed-top {\n    /* position: fixed; */\n    position: static !important;\n    top: 0;\n    right: 0;\n    left: 0;\n    z-index: 1030;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
