import React, { useState, useContext, useEffect } from "react";
import adminLayout from "../hoc/adminLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/css/AgentPrivileges.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";

const AgentPrivileges = () => {
  const { user } = useContext(AuthContext);

  const [formState, setFormState] = useState({
    roleId: "",
    teamId: "",
    overview: "Inactive",
    realTimeTracking: "Inactive",
    analytics: "Inactive",
    timeSpent: "Inactive",
    reports: "Inactive",
    fieldAgent: "Inactive",
    fieldAgents: {
      fieldAgentAdd: "Inactive",
      fieldAgentLists: "Inactive",
      fieldAgentList: {
        view: "Inactive",
        edit: "Inactive",
        delete: "Inactive",
      },
      agentPrivileges: "Inactive",
      agentTeamPrivileges: "Inactive",
      agentTeamAdd: "Inactive",
      agentTeamLists: "Inactive",
      agentTeamList: {
        view: "Inactive",
        edit: "Inactive",
        delete: "Inactive",
      },
    },
  });

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [teams, setTeams] = useState([]);

  // Fetch roles and teams from API
  useEffect(() => {
    if (user) {
      fetchDropdownData(user);
    }
  }, [user]);

  const fetchDropdownData = async (user) => {
    try {
      const response = await axios.get(
        `https://uat-tracking.rmtec.in/api/fieldAgent/getAgentById/${user.fieldAgentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const teamData = response.data.data.teamIds;
      setTeams(teamData);
    } catch (error) {
      console.error("Error fetching roles or teams:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, checked, dataset } = event.target;
    const isCheckbox = event.target.type === "checkbox";

    if (dataset.section) {
      setFormState((prevState) => ({
        ...prevState,
        fieldAgents: {
          ...prevState.fieldAgents,
          [dataset.section]: isCheckbox
            ? checked
              ? "Active"
              : "Inactive"
            : value,
        },
      }));
    } else if (dataset.subSection) {
      setFormState((prevState) => ({
        ...prevState,
        fieldAgents: {
          ...prevState.fieldAgents,
          [dataset.main]: {
            ...prevState.fieldAgents[dataset.main],
            [dataset.subSection]: isCheckbox
              ? checked
                ? "Active"
                : "Inactive"
              : value,
          },
        },
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: isCheckbox ? (checked ? "Active" : "Inactive") : value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      role: { roleId: user.role.roleId },
      team: { teamId: formState.teamId },
      overview: formState.overview,
      realTimeTracking: formState.realTimeTracking,
      analytics: formState.analytics,
      timeSpent: formState.timeSpent,
      reports: formState.reports,
      fieldAgent: formState.fieldAgent,
      fieldAgents: formState.fieldAgents,
    };

    try {
      await axios.post(
        "https://uat-tracking.rmtec.in/api/privilege/addPrivilege",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setSuccessMessage("Agent Privileges created successfully!");
      resetFormState();
      setTimeout(() => {
        handleCloseMessage();
      }, 2000);
    } catch (error) {
      console.error("Error adding agent privileges:", error);
      setErrorMessage("Failed to create agent privileges.");
    } finally {
      setLoading(false);
    }
  };

  const resetFormState = () => {
    setFormState({
      roleId: "",
      teamId: "",
      overview: "Inactive",
      realTimeTracking: "Inactive",
      analytics: "Inactive",
      timeSpent: "Inactive",
      reports: "Inactive",
      fieldAgent: "Inactive",
      fieldAgents: {
        fieldAgentAdd: "Inactive",
        fieldAgentLists: "Inactive",
        fieldAgentList: {
          view: "Inactive",
          edit: "Inactive",
          delete: "Inactive",
        },
        agentPrivileges: "Inactive",
        agentTeamPrivileges: "Inactive",
        agentTeamAdd: "Inactive",
        agentTeamLists: "Inactive",
        agentTeamList: {
          view: "Inactive",
          edit: "Inactive",
          delete: "Inactive",
        },
      },
    });
  };

  const handleCloseMessage = () => {
    setErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Agent Team Privileges - RealTimeTracking</title>
        </Helmet>
      </HelmetProvider>
      <h3 className="title">   Agent Team Privileges</h3>
      <div className="container agentprivileges">
      <span
                            className="breadcrumb-allpage"
                            style={{ borderBottom: '2px solid #0d6efd', display: 'block' }}
                        >
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/field-agent-management">
                                            <i className="fa fa-home" style={{ marginRight: '5px' }}></i>
                                            Field Agent Management
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <i className="fa fa-user-shield" style={{ marginRight: '5px' }}></i>
                                        Agent Team Privileges
                                    </li>
                                </ol>
                            </nav>
                        </span>
        <form id="privilegesForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="teamId">Teams List</label>
            <select
              id="teamId"
              name="teamId"
              className="form-control"
              value={formState.teamId}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="">Select Team</option>
              {teams.map((team) => (
                <option key={team.teamId} value={team.teamId}>
                  {team.teamName}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-4">
  <table className="table table-striped table-hover">
    <thead className="thead-dark">
      <tr>
        <th>Menu</th>
        <th>Submenu</th>
        <th>View</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      {["overview", "realTimeTracking", "analytics", "timeSpent", "reports", "fieldAgent"].map((field) => (
        <tr key={field}>
          <td>{field} <input
              type="checkbox"
              name={field}
              className="form-check-input ml-3"
              checked={formState[field] === "Active"}
              onChange={handleInputChange}
            />{" "}
            {/* {formState[field] === "Active" ? "Active" : "Inactive"}</td> */}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      ))}

      {/* Field Agent Privileges */}
      {formState.fieldAgent === "Active" &&
        Object.keys(formState.fieldAgents).map((key) => {
          if (typeof formState.fieldAgents[key] === "object") {
            return (
              <React.Fragment key={key}>
                <tr>
                <td></td>
                  <td  className="font-weight-bold">
                    {key}
                  </td>
                 
                </tr>
                {Object.keys(formState.fieldAgents[key]).map((subKey) => (
                  <tr key={`${key}-${subKey}`}>
                    <td></td>
                    <td>{subKey}</td>
                    <td>
                      <input
                        type="checkbox"
                        data-main={key}
                        data-sub-section={subKey}
                        className="form-check-input"
                        checked={formState.fieldAgents[key][subKey] === "Active"}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        data-main={key}
                        data-sub-section={subKey}
                        className="form-check-input"
                        checked={formState.fieldAgents[key][subKey] === "Active"}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        data-main={key}
                        data-sub-section={subKey}
                        className="form-check-input"
                        checked={formState.fieldAgents[key][subKey] === "Active"}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            );
          }

          return (
            <tr key={key}>
              <td></td>
              <td>{key}</td>
              <td>
                <input
                  type="checkbox"
                  data-section={key}
                  className="form-check-input"
                  checked={formState.fieldAgents[key] === "Active"}
                  onChange={handleInputChange}
                />
              </td>
              <td></td>
              <td></td>
            </tr>
          );
        })}
    </tbody>
  </table>
</div>

          <button type="submit" className="btn btn-primary fieldadd-btn" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
          {successMessage && (
            <div className="alert alert-success mt-3">{successMessage}</div>
          )}
          {errorMessage && (
            <div className="alert alert-danger mt-3">{errorMessage}</div>
          )}
        </form>
      </div>
    </>
  );
};

export default adminLayout(AgentPrivileges);
