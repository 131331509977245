import React, { useState, useEffect, useContext } from "react";
import adminLayout from "../hoc/adminLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/css/TimeSpent.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  FaEdit,
  FaEye,
  FaTrashAlt,
  FaFileExcel,
  FaFilePdf,
} from "react-icons/fa";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useHasPermission from "../utils/permission/pageAccess";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import teamDetailsService from "../services/TeamDetailsService";

const TimeSpent = () => {
  const { render } = useHasPermission({
    moduleName: "timeSpent",
  });
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    fromDate: "",
    toDate: "",
    team: "",
    manager: "",
  });
  const { user } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [teamData, setTeamData] = useState(null);
  const itemsPerPage = 5;

  useEffect(() => {
    if (user) {
      handleTimeSpent(user);
      handleTeamDetails(user?.fieldAgentId);
    }
  }, [user]);

  const handleTeamDetails = async (id) => {
    try {
      const payload = {
        fieldAgentId: id , // Ensure a valid ID is passed
      };
      const resp = await teamDetailsService.getAllTeamList(payload);
      if (resp && resp.statuscode !== 500) {
        console.log("Team Details Response:", resp);
        setTeamData(resp?.data || []); // Safely set team data if available
      } else {
        console.error("Error fetching team details:", resp);
      }
    } catch (error) {
      console.error("Error in handleTeamDetails:", error.message || error);
    }
  };

  const handleTimeSpent = async (user) => {
    const token = localStorage.getItem("token");

    await axios
      .post(
        "https://uat-tracking.rmtec.in/api/liveTrackingId/getAllLiveTrackingIdDetails",
        {
          query: "",
          variables: {
            userId: `${user && user?.fieldAgentId}`,
            teamId: ``,
            roleId: ``,
            startDate: "",
            endDate: "",
            agentMail: "",
            timeSpentDetail: "true",
            search: "",
            page: 0,
            limit: 20,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )

      .then((resp) => {
        const teamsData = resp ? resp?.data.data.content : [];
        setTeams(teamsData);
        setFilteredTeams(teamsData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };
  useEffect(() => {
    applyFilters();
  }, [filters, teams]);

  // Filter Logic
  const applyFilters = () => {
    let filtered = [...teams];
    if (filters.name) {
      filtered = filtered.filter(
        (team) =>
          team.nickName &&
          team.nickName.toLowerCase().includes(filters.name.toLowerCase())
      );
    }
    if (filters.fromDate) {
      const fromDate = new Date(filters.fromDate);
      filtered = filtered.filter(
        (team) =>
          team.date && new Date(team.date).setHours(0, 0, 0, 0) >= fromDate
      );
    }
    if (filters.toDate) {
      const toDate = new Date(filters.toDate);
      filtered = filtered.filter(
        (team) =>
          team.date && new Date(team.date).setHours(0, 0, 0, 0) <= toDate
      );
    }
    if (filters.team) {
      filtered = filtered.filter(
        (team) =>
          team.teamName &&
          team.teamName.toLowerCase().includes(filters.team.toLowerCase())
      );
    }
    if (filters.manager) {
      filtered = filtered.filter(
        (team) =>
          team.managerName &&
          team.managerName.toLowerCase().includes(filters.manager.toLowerCase())
      );
    }
    setFilteredTeams(filtered);
    setCurrentPage(1);
  };

  // Filter Input Handler
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Pagination
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTeams = filteredTeams.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredTeams.length / itemsPerPage);

  // Export to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredTeams);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "TimeSpentData");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "TimeSpentReport.xlsx");
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const columns = [
      "Date",
      "Field Agents",
      "Team Name",
      "Place Name",
      "Start Time",
      "End Time",
      "Time Spent",
    ];

    // Format date to display only the date part
    const formatDate = (date) => {
      return new Date(date).toLocaleDateString("en-US"); // Format date to MM/DD/YYYY
    };

    const rows = filteredTeams.map((team) => [
      formatDate(team.date), // Use the formatDate function to get only the date
      team.nickName,
      team.teamName,
      team.fieldTag,
      team.loginTime,
      team.logoutTime,
      team.totalTimeSpent,
    ]);

    doc.text("Time Spent Report", 14, 10);
    doc.autoTable({ startY: 20, head: [columns], body: rows });
    doc.save("TimeSpentReport.pdf");
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Time Spent - RealTimeTracking</title>
        </Helmet>
      </HelmetProvider>
      {render && (
        <>
          <h3 className="title">Time Spent</h3>
          <div className="container mt-4 fieldagentmange">
            {/* Filters */}
            <div className="row mb-4 bg-light p-3 rounded shadow-sm">
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter by Name"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <input
                  type="date"
                  className="form-control"
                  name="fromDate"
                  value={filters.fromDate}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-2">
                <input
                  type="date"
                  className="form-control"
                  name="toDate"
                  value={filters.toDate}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter by Team"
                  name="team"
                  value={filters.team}
                  onChange={handleFilterChange}
                />
              </div>
              {/* <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Filter by Manager"
                                    name="manager"
                                    value={filters.manager}
                                    onChange={handleFilterChange}
                                />
                            </div> */}
            </div>

            {/* Export Buttons */}
            <div className="mb-3">
              <button
                className="btn  me-2 fieldadd-btn"
                onClick={exportToExcel}
              >
                <FaFileExcel /> Export to Excel
              </button>
              <button className="btn  fieldadd-btn" onClick={exportToPDF}>
                <FaFilePdf /> Export to PDF
              </button>
            </div>

            {/* Table */}
            <table className="table table-striped">
              <thead className="table-dark">
                <tr>
                  <th>Date</th>
                  <th>Field Agents</th>
                  <th>Team Name</th>
                  <th>Place Name</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Time Spent</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {currentTeams.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  currentTeams.map((team) => (
                    <tr key={team.teamId}>
                      <td>{new Date(team.date).toLocaleDateString()}</td>
                      <td>{team.nickName}</td>
                      <td>{team.teamName}</td>
                      <td>{team.fieldTag}</td>
                      <td>{team.startTime}</td>
                      <td>{team.endTime}</td>
                      <td>{team.totalTimeSpent}</td>
                      {/* <td>
                        <FaEdit className="action-icon me-2" title="Edit" />
                        <FaEye className="action-icon me-2" title="View" />
                        <FaTrashAlt className="action-icon" title="Delete" />
                      </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>

            {/* Pagination */}
            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-center">
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </>
      )}
    </>
  );
};

export default adminLayout(TimeSpent);
