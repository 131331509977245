import React, { useState, useEffect, useContext } from "react";
import { Pie, Bar, Line } from "react-chartjs-2";
import "../assets/css/charts.css";
import { Card, ListGroup, Tab, Nav, Form } from "react-bootstrap";

// import 'https://js.api.here.com/v3/3.1/mapsjs-ui.css'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
} from "chart.js";

import { AuthContext } from "../context/AuthContext";

// Register the necessary components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement
);

const AnalyticsComponent = () => {
  const [counter, setCounter] = useState(0);
  const [numberValue, setNumberValue] = useState(100); // Initialize with some value
  const [userData, setUserData] = useState(null);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, []);

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = (error) => {
          console.error(`Failed to load script: ${src}`, error);
          reject(new Error(`Failed to load ${src}`));
        };
        document.head.appendChild(script);
      });
    };

    const loadHereMapsScripts = async () => {
      try {
        console.log("Loading HERE Maps Scripts...");
        await loadScript("https://js.api.here.com/v3/3.1/mapsjs-core.js");
        await loadScript("https://js.api.here.com/v3/3.1/mapsjs-service.js");
        await loadScript("https://js.api.here.com/v3/3.1/mapsjs-ui.js");
        await loadScript("https://js.api.here.com/v3/3.1/mapsjs-mapevents.js");

        const H = window.H;
        if (!H) {
          throw new Error("HERE Maps API is not available");
        }

        const platform = new H.service.Platform({
          apikey: "pQRBSzF1TdNTk2W6Z5pCNpDtmGAYH624qC7LU0cf6Xw", // Replace with your API key
        });

        const defaultLayers = platform.createDefaultLayers();
        const map = new H.Map(
          document.getElementById("mapContainer"),
          defaultLayers.vector.normal.map,
          {
            zoom: 15,
            center: { lat: 11.08, lng: 76.995 },
          }
        );

        const behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(map)
        );
        const ui = H.ui.UI.createDefault(map, defaultLayers);

        // Function to fetch live tracking data dynamically
        const fetchLiveTrackingData = async (map, ui) => {
          try {
            const response = await fetch(
              "https://uat-tracking.rmtec.in/api/liveTrackingId/getAllLiveTrackingIdDetails",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                  query: "",
                  variables: {
                    fieldAgentId: userData?.fieldAgentId,
                    roleId: userData.role.userId,
                    agentMail: "",
                    startDate: "2024-11-29",
                    endDate: "2024-11-29",
                    teamId: "2054",
                    search: "",
                    page: 0,
                    limit: 20,
                  },
                }),
              }
            );

            const data = await response.json();

            if (data && data.data && data.data.content) {
              return data.data.content; // Return the live tracking details
            } else {
              console.error("No live tracking data found.");
              return [];
            }
          } catch (error) {
            console.error("Error fetching live tracking data:", error);
            return [];
          }
        };

        // Fetch dynamic tracking data and process
        const liveTrackingDetails = await fetchLiveTrackingData();
        console.log("Live Tracking Details:", liveTrackingDetails);

        const lineColors = ["blue", "red", "green", "purple", "orange"];
        const userIcons = [
          "https://img.icons8.com/color/48/000000/marker.png", // Icon for User 1
          "https://img.icons8.com/office/48/000000/marker.png", // Icon for User 2
          "https://img.icons8.com/ios/48/000000/marker.png", // Icon for User 3
          "https://img.icons8.com/plasticine/48/000000/marker.png", // Icon for User 4
          "https://img.icons8.com/color/48/000000/marker.png", // Icon for User 5
        ];

        // Group the live tracking data by field agent (or another identifier if necessary)
        const agentsData = {};

        liveTrackingDetails.forEach((detail) => {
          const agentId = detail.fieldAgentId;
          if (!agentsData[agentId]) {
            agentsData[agentId] = [];
          }
          agentsData[agentId].push({
            lat: parseFloat(detail.latitude),
            lng: parseFloat(detail.longitude),
          });
        });

        // Add markers and routes for each agent
        let userIndex = 0;
        Object.keys(agentsData).forEach((agentId) => {
          const points = agentsData[agentId];

          // Add a marker for each point (location)
          points.forEach((point, index) => {
            const marker = new H.map.Marker(point, {
              icon: new H.map.Icon(userIcons[userIndex % userIcons.length]), // Use icon based on user index
            });

            marker.setData(
              index === 0
                ? `Agent ${agentId} Start`
                : index === points.length - 1
                ? `Agent ${agentId} End`
                : `Agent ${agentId} Point ${index}`
            );
            map.addObject(marker);
          });

          // Create and add polylines for the route between points
          for (let i = 0; i < points.length - 1; i++) {
            const origin = points[i];
            const destination = points[i + 1];

            const routeRequest = `https://router.hereapi.com/v8/routes?apikey=pQRBSzF1TdNTk2W6Z5pCNpDtmGAYH624qC7LU0cf6Xw&transportMode=car&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&return=polyline`;

            fetch(routeRequest)
              .then((response) => response.json())
              .then((data) => {
                if (data.routes && data.routes[0]) {
                  const route = data.routes[0];
                  const routeShape = route.sections[0].polyline;
                  const routeLineString =
                    H.geo.LineString.fromFlexiblePolyline(routeShape);

                  const routePolyline = new H.map.Polyline(routeLineString, {
                    style: {
                      lineWidth: 6,
                      strokeColor: lineColors[userIndex % lineColors.length],
                    },
                  });

                  map.addObject(routePolyline);
                  map.getViewModel().setLookAtData({
                    bounds: routePolyline.getBoundingBox(),
                  });
                } else {
                  console.error(
                    `No route found for segment from ${origin.lat},${origin.lng} to ${destination.lat},${destination.lng}`
                  );
                }
              })
              .catch((error) => {
                console.error("Error fetching route:", error);
              });
          }

          userIndex++; // Increment userIndex for each agent
        });
      } catch (error) {
        console.error(
          "Error loading HERE Maps or processing live tracking data:",
          error
        );
      }
    };

    loadHereMapsScripts();

    return () => {
      document
        .querySelectorAll('script[src^="https://js.api.here.com"]')
        .forEach((script) => {
          document.head.removeChild(script);
        });
    };
  }, []);

  const [heatmapData, setHeatmapData] = useState([]);

  // Function to load HERE Maps scripts dynamically
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://uat-tracking.rmtec.in/api/placeOfInterest/getPlaceOfInterestDetailWithOrWithoutFilter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: "",
            variables: {
              teamId: "2054",
              fieldAgentId: "103",
              agentMail: "",
              categoryName: "",
              title: "",
              processedStartDate: "",
              processedEndDate: "",
              description: "",
              search: "",
              page: 0,
              limit: 2000000000,
            },
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data && data.data && data.data.content) {
        const locations = data.data.content.map((item) => ({
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
          intensity: 0.5, // Adjust based on your data
          color: "#FFEB99", // Adjust color as necessary
        }));
        console.log("Heatmap Data:", locations); // Log the locations to ensure they are valid
        setHeatmapData(locations);
      } else {
        console.error("Invalid data structure:", data);
      }
    } catch (error) {
      console.error("Error fetching heatmap data:", error);
    }
  };

  // Initialize the map and add heatmap layer
  const loadHereMapsScripts = async () => {
    try {
      // Load HERE Maps scripts dynamically
      await loadScript("https://js.api.here.com/v3/3.1/mapsjs-core.js");
      await loadScript("https://js.api.here.com/v3/3.1/mapsjs-service.js");
      await loadScript("https://js.api.here.com/v3/3.1/mapsjs-ui.js");
      await loadScript("https://js.api.here.com/v3/3.1/mapsjs-mapevents.js");

      const H = window.H;

      // Initialize the platform with your API key
      const platform = new H.service.Platform({
        apikey: "pQRBSzF1TdNTk2W6Z5pCNpDtmGAYH624qC7LU0cf6Xw", // Replace with your HERE Maps API key
      });

      const defaultLayers = platform.createDefaultLayers();

      // Initialize the map
      const map = new H.Map(
        document.getElementById("mapContainer3"),
        defaultLayers.vector.normal.map,
        {
          zoom: 10,
          center: { lat: 11.08, lng: 76.995 }, // Initial center point
        }
      );

      // Enable map interactions like zoom and pan
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      const ui = H.ui.UI.createDefault(map, defaultLayers);

      // Create a heatmap provider with the fetched heatmap data
      const heatmapProvider = new H.data.heatmap.Provider({
        dataPoints: heatmapData.map(
          (location) =>
            new H.data.heatmap.DataPoint(
              location.lat,
              location.lng,
              location.intensity
            )
        ),
        type: "density",
        intensity: 0.7, // Adjust heat intensity
        coarseness: 1, // Adjust to change pixel size of heat spots
      });

      // Add the heatmap layer to the map
      const heatmapLayer = new H.map.layer.TileLayer(heatmapProvider);
      map.addLayer(heatmapLayer);
    } catch (error) {
      console.error(
        "Error loading HERE Maps scripts or creating the heatmap:",
        error
      );
    }
  };

  // useEffect hook to fetch data and load map
  useEffect(() => {
    fetchData(); // Fetch the data from the API
    loadHereMapsScripts(); // Load HERE Maps and initialize

    return () => {
      document
        .querySelectorAll('script[src^="https://js.api.here.com"]')
        .forEach((script) => {
          document.head.removeChild(script);
        });
    };
  }, [heatmapData]);

  return (
    <div className="container mt-5">
      {/* <Tab.Container defaultActiveKey="routeAnalysis">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="routeAnalysis">Route Analysis</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="frequentlyVisited">Frequently Visited Locations</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="placeInterest">Place Of Interest</Nav.Link>
                                    </Nav.Item>
                                   
                                </Nav>
                                <Tab.Content className="mt-3">
                                    <Tab.Pane eventKey="routeAnalysis">
                                    <div id="mapContainer3" style={{ width: '100%', height: '400px' }}></div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="frequentlyVisited">
                                    <div id="mapContainer" style={{ width: '100%', height: '400px' }}></div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="placeInterest">
                                    <div id="mapContainer2" style={{ width: '100%', height: '400px' }}></div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container> */}
      {/* Charts Section */}
      <div className="row">
        <div className="col-md-6 mb-4 App">
          <div className="card">
            <div className="card-header">Route Analysis</div>
            <div className="card-body">
              <div
                id="mapContainer"
                style={{ width: "100%", height: "400px" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 App">
          <div className="card">
            <div className="card-header">Frequently Visited Locations</div>
            <div className="card-body">
              <div
                id="mapContainer3"
                style={{ width: "100%", height: "400px" }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 App">
          <div className="card">
            <div className="card-header">Place Of Interest</div>
            <div className="card-body">
              <div
                id="mapContainer2"
                style={{ width: "100%", height: "400px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsComponent;
