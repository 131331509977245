import httpService from '../utils/http'

class FieldAgentService {

    async getFieldList(data) {
        return httpService.post(`api/fieldAgent/getAllFieldAgentDetails`, data)
    }

    async getSingleFieldAgent(id) {
        return httpService.get(`/api/fieldAgent/getAgentById/${id}`)
    }

    async deleteFieldAgent(id) {
        return httpService.delete(`api/fieldAgent/deleteFieldAgentById/${id}`)
    }

    async updateFieldAgent(id, data) {
        return httpService.put(`api/fieldAgent/updateFieldAgentById/${id}`, data)
    }
}

export default new FieldAgentService()

