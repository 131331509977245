import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";

const TrackingDetails = ({ formData }) => {
  console.log("🚀 ~ TrackingDetails ~ data:", formData)
  // const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext)

  //   useEffect(()=>{
  // if(user){
  //   fetchRealTimeTrackingDetails(user)
  // }
  //   },[user])

  //   // Fetch real-time tracking details
  //   const fetchRealTimeTrackingDetails = async (user) => {
  //     console.log('----------user resp----->');

  //     try {
  //       const response = await fetch(
  //         `https://uat-tracking.rmtec.in/api/fieldAgent/realTimeTrackingDetails/${user?.fieldAgentId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`, // Use Bearer token for secure access
  //           },
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error(`Error: ${response.status} - ${response.statusText}`);
  //       }

  //       const responseData = await response.json();
  //       console.log('res--------------------->:',responseData)
  //       setData(responseData.placeOfInterestDetails || []); // Set data
  //     } catch (err) {
  //       setError(err.message); // Set error message
  //     }
  //   };

  //   useEffect(() => {
  //     fetchRealTimeTrackingDetails();
  //   }, [agentId, token]);

  return (
    <div>
      <h3>Real-Time Tracking Details</h3>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            <th>Location</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {formData?.placeOfInterestDetails.length > 0 ? (
            formData?.placeOfInterestDetails.map((item) => (
              <tr key={item.id}>
                <td>{new Date(item.date).toLocaleString()}</td>
                <td>{item.title}</td>
                <td>{item.description}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TrackingDetails;
