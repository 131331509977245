// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-con{
    /* margin: 0 130px; Center the form */
   /* padding: 20px;  Padding inside the form */
   /*  border-radius: 10px; Rounded corners */
  /*  background-color: white;  White background for the form */
   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  Subtle shadow */
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Overview.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;GACtC,4CAA4C;GAC5C,0CAA0C;EAC3C,6DAA6D;GAC5D,4DAA4D;AAC/D","sourcesContent":[".overview-con{\n    /* margin: 0 130px; Center the form */\n   /* padding: 20px;  Padding inside the form */\n   /*  border-radius: 10px; Rounded corners */\n  /*  background-color: white;  White background for the form */\n   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  Subtle shadow */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
